export default {
    state: {
        menu: [
            { product: true, title: 'Finance Hub', bgcolor: 'caccounting' },
            // { button: true, title: 'Create Analysis', href: '/tds/ar#add' },
            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            {
                title: 'Home', icon: 'mdi mdi-home-outline', href: '/financialclose/home', right: "financialclose_project"
            },
            { submenu: true, variable: 'tabs'},
            { submenu: true, variable: 'vatabs'},
            { header: true, title: 'FC Masters' },
            {
                title: 'Chart of Accounts',
                icon: 'mdi mdi-sitemap',
                href: '/financialclose/manage/chartofaccount',
                right: "financialclose_master_chartofaccount"
            },
            {
                title: 'Tasklist',
                icon: 'mdi mdi-layers-triple-outline',
                href: '/financialclose/manage/checklist',
                right: "financialclose_master_checklist"
            },
            {
                title: 'Information Manager',
                icon: 'mdi-tune-vertical-variant',
                href: '/financialclose/manage/informationmanager',
                right: "financialclose_master_informationmanager"
            },
            {
                title: 'Location',
                icon: 'mdi-map-marker-multiple-outline',
                href: '/financialclose/manage/location',
                right: "financialclose_master_checklist",
                edit:true
            },
            {
                title: 'Threshold',
                icon: 'mdi-waves',
                href: '/financialclose/manage/threshold',
                right: "financialclose_master_checklist"
            },
            { header: true, title: 'Help' },
            {
                title: 'Support', 
                icon: 'mdi-lifebuoy', 
                href: '/financialclose/support',
                right: "helpdesk"
            },
            {
                title: 'Setting', 
                icon: 'mdi-cog-outline', 
                href: '/financialclose/setting',
                right: "helpdesk"
            },
        ],
        projectid: "",
        tabs: [
            { header: true, title: 'This Cycle' },
            {
                title: 'Dashboard',
                icon: 'mdi mdi-view-dashboard',
                href: '/financialclose/project/dashboard/$$id$$',
                right: "financialclose_analysis"
            },
            {
                title: 'Tasklist',
                icon: 'mdi mdi-layers-triple-outline',
                href: '/financialclose/project/checklist/$$id$$',
                right: "financialclose_analysis"
            },
            {
                title: 'Trial Balance',
                icon: 'mdi mdi-clipboard-text-outline',
                href: '/financialclose/project/trialbalance/$$id$$',
                right: "financialclose_analysis"
            },
            {
                title: 'Users',
                icon: 'mdi mdi-account-group',
                href: '/financialclose/project/users/$$id$$',
                right: "financialclose_analysis"
            },
            {
                title: 'Threshold',
                icon: 'mdi mdi-waves',
                href: '/financialclose/project/threshold/$$id$$',
                right: "financialclose_analysis"
            },
            {
                title: 'Information Manager',
                icon: 'mdi mdi-message-bulleted',
                href: '/financialclose/project/informationmanager/$$id$$',
                right: "financialclose_analysis",
            },
            { header: true, title: 'Automation Agent' },
            {
                title: 'Transaction Matching', 
                icon: 'mdi-bank-outline', 
                href: '/financialclose/automationtransactionmatching/$$id$$/',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            {
                title: 'Lease Schedule', 
                icon: 'mdi-calendar-month-outline', 
                href: '/financialclose/automationlease/$$id$$/',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            {
                title: 'Borrowing Schedule', 
                icon: 'mdi-note-text-outline', 
                href: '/financialclose/automationborrowingschedule/$$id$$/',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            { header: true, title: 'Bolt-on Modules' },
            {
                title: 'Virtual Concierge', 
                icon: 'mdi-face-agent', 
                href: '/financialclose/functionalsupport/$$id$$/',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            {
                title: 'Variance Analysis',
                icon: 'mdi mdi-vector-difference',
                href: '/financialclose/project/varianceanalysis/$$id$$',
                right: "financialclose_analysis",
                extrapad: 3,
            },
        ],
        vatabs:[
            { subheader: true, title: 'This Variance Analysis' },
            {
                title: 'Analysis',
                icon: 'mdi mdi-vector-difference',
                href: '/financialclose/project/$$id$$/varianceanalysis/analysis/$$vid$$',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            {
                title: 'Configuration',
                icon: 'mdi mdi-tune',
                href: '/financialclose/project/$$id$$/varianceanalysis/configuration/$$vid$$',
                right: "financialclose_analysis",
                extrapad: 3,
            },
            {
                title: 'Users',
                icon: 'mdi mdi-account-group',
                href: '/financialclose/project/$$id$$/varianceanalysis/users/$$vid$$',
                right: "financialclose_analysis",
                extrapad: 3,
            },
        ]    
    },
    mutations: {
        setProject(state, d) {
            state.projectid = d;
        },
    },
    actions: {
    },
    getters: {

    },

}